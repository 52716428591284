@use '../../../../common/styleConstants.scss' as *;

.container {
  width: 100%; 
  margin-top: 8px;

  .labels {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 4px;

    span {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .bar {
    display: flex;
    height: 24px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $bright-gray;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .square {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    margin-right: 4px;
  }

  .whole {
    background-color: $blue-ryb;
  }

  .tail {
    background-color: $dark-orange;
  }

  .waste {
    background-color: $silver-chalice;
  }
}