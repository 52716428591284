@use '../../common/styleConstants.scss' as *;

.sider {
  min-width: 80px !important;
  min-height: 100vh;

  .logo {
    justify-content: center;
    text-align: center;
    padding: 30px 0px;
    cursor: pointer;
  }

  .subItemSelected {
    background-color: $item-selected-dark;
  }

  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :local .sidebarMenu {
        padding: 0px 16px;
      }

      .ant-menu-item {
        display: flex;
        align-items: center;
        padding-left: 14px !important;
        border-radius: 8px;
        margin: 4px 0 8px 0;

        i {
          margin: 0 0 0 -2px !important;
          font-size: 24px !important;
        }

        span {
          font-size: 13px;
          font-weight: 600;
        }
      }

      .ant-menu-submenu {
        margin: 4px 0 8px;
      }

      .ant-menu-submenu.ant-menu-submenu-vertical {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-menu-submenu.ant-menu-submenu-inline,
      .ant-menu-submenu.ant-menu-submenu-vertical {
        position: relative;
        margin-bottom: 24px;
        border-radius: 8px;

        .ant-menu-submenu-title {
          border-radius: 8px;
          margin: 0px;
          padding-left: 13px !important;

          i {
            font-size: 22px;
          }

          .ant-menu-item-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            width: 24px;
            margin: 1px 0 0 -1px;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .ant-menu-title-content {
            font-size: 13px;
            font-weight: 600;
          }
        }

        .ant-menu-submenu-title:hover {
          border-radius: 8px;
        }
      }

      .ant-menu-item:not(:last-child) {
        margin-bottom: 24px;
      }

      .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background-color: transparent;

        .ant-menu-item-only-child {
          width: 90%;
          float: right;
          height: 28px;
          margin: 6px 0 4px 0;

          .ant-menu-title-content {
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .sider {
    display: none;
  }
}

.siderDark {
  transition: 0s;
  background-color: $sider-background-dark;
  border-right: 2px solid $sider-border-dark;
  border-bottom: 2px solid $sider-border-dark;

  .logo {
    background-color: $sider-background-dark;
  }

  :global {
    .ant-layout-sider-children {
      :local .sidebarMenu {
        transition: 0s;
        background-color: $sider-background-dark;

        :global {
          .ant-menu-submenu-arrow {

            &::before,
            &::after {
              background-color: $title-item-dark;
            }
          }
        }
      }
    }

    .ant-layout-sider-children {
      background-color: $sider-background-dark;

      .ant-menu-submenu-selected:not(.ant-menu-submenu-open) .ant-menu-submenu-title,
      .ant-menu-item.ant-menu-item-selected {
        background-color: $item-selected-dark;

        .ant-menu-item-icon {
          color: $title-item-hover-dark;
        }

        .ant-menu-title-content {
          color: $title-item-hover-dark;
        }

        .ant-menu-submenu-arrow {

          &::before,
          &::after {
            transition: 0.2s;
            background-color: $title-item-hover-dark;
          }
        }
      }

      .ant-menu-item,
      .ant-menu-submenu {
        transition: 0s;
        background-color: $sider-background-dark;

        .ant-menu-title-content {
          color: $title-item-dark;
        }

        .ant-menu-item-icon {
          color: $title-item-dark;
        }
      }

      .ant-menu-item {
        transition: 0s;
        background-color: $sider-background-dark;

        .ant-menu-title-content {
          color: $title-item-dark;
        }

        .ant-menu-item-icon {
          color: $title-item-dark;
        }
      }

      .ant-menu-submenu.ant-menu-submenu-vertical {
        &:hover {
          background-color: $item-selected-dark;
        }
      }

      .ant-menu-item-selected {
        i::before {
          transition: 0.2s;
          color: $bright-gray;
        }

        .ant-menu-title-content {
          color: $title-item-dark;
        }
      }
    }

    .ant-layout-sider-children .ant-menu-submenu.ant-menu-submenu-inline {
      .ant-menu-submenu-title:hover {
        background-color: $item-selected-dark;

        .ant-menu-item-icon {
          color: $bright-gray !important;
        }
      }
    }

    .ant-menu-item:hover {
      background-color: $item-selected-dark;

      .ant-menu-item-icon {
        color: $bright-gray;
      }
    }
  }
}

.siderLight {
  transition: 0s;
  background-color: $sider-background-light;
  border-right: 2px solid $sider-border-light;
  border-bottom: 2px solid $sider-border-light;

  .logo {
    background-color: $sider-background-light;
  }

  :global {
    .ant-layout-sider-children {
      :local .sidebarMenu {
        transition: 0s;
        background-color: $sider-background-light;

        :global {
          .ant-menu-submenu-arrow {

            &::before,
            &::after {
              transition: 0.2s;
              background-color: $menu-arrow-color-light;
            }
          }

          .ant-menu-submenu-title {
            &:hover {
              .ant-menu-submenu-arrow {

                &::before,
                &::after {
                  transition: 0.2s;
                  background-color: $sider-background-light;
                }
              }
            }
          }
        }
      }
    }

    .ant-layout-sider-children {

      .ant-menu-submenu-selected:not(.ant-menu-submenu-open) .ant-menu-submenu-title,
      .ant-menu-item.ant-menu-item-selected {
        transition: 0s;
        background-color: $item-selected-light;

        .ant-menu-item-icon {
          transition: 0s;
          color: $title-item-hover-light;
        }

        .ant-menu-title-content {
          transition: 0s;
          color: $title-item-hover-light;
        }

        .ant-menu-submenu-arrow {

          &::before,
          &::after {
            transition: 0.2s;
            background-color: $sider-background-light;
          }
        }
      }

      .ant-menu-item,
      .ant-menu-submenu {
        transition: 0s;
        background-color: $sider-background-light;

        :local .subItemSelected {
          background-color: $item-selected-light;

          :global {
            .ant-menu-title-content {
              color: $title-item-hover-light;
            }
          }
        }

        .ant-menu-title-content {
          transition: 0.2s;
          color: $title-item-light;
        }

        .ant-menu-item-icon {
          transition: 0.2s;
          color: $title-item-light;
        }
      }

      .ant-menu-item {
        &:hover {
          transition: 0.2s;
          background-color: $item-selected-light;

          i::before {
            transition: 0.2s;
            color: $title-item-hover-light;
          }

          .ant-menu-title-content {
            transition: 0.2s;
            color: $title-item-hover-light;
          }
        }
      }

      .ant-menu-submenu.ant-menu-submenu-vertical {
        &:hover {
          background-color: $item-selected-light;

          i,
          svg {
            color: $white;
          }
        }
      }

      .ant-menu-item-selected {
        i::before {
          transition: 0.2s;
          color: $title-item-dark;
        }

        .ant-menu-title-content {
          color: $title-item-dark;
        }
      }
    }

    .ant-layout-sider-children .ant-menu-submenu.ant-menu-submenu-inline {
      .ant-menu-submenu-title:hover {
        background-color: $item-selected-light;

        .ant-menu-item-icon {
          color: $title-item-hover-light;
        }

        .ant-menu-title-content {
          color: $title-item-hover-light;
        }
      }
    }

    .ant-menu-item:hover {
      background-color: $item-selected-light;

      .ant-menu-item-icon {
        color: $title-item-hover-light;
      }
    }
  }
}

.paddingLeft {
  padding-left: 25px;
}

.profileBottomContainer {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;

  .avatar {
    background-color: $black;
    border: 2px solid $gray;
    width: 40px;
    height: 40px;

    .avatarLabel {
      color: $white;
    }
  }

  .labelsContent {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 8px;
    text-align: left;

    .nameText {
      color: $white;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: -10px;
      min-width: 135px;
    }

    .emailText {
      margin: 0px;
      font-size: 12px;
      color: $title-item-dark;

      min-width: 135px;
    }
  }
}

.profileBottomContainerDark {
  .avatar {
    background-color: $black;
    border: 2px solid $dim-gray-a;

    .avatarLabel {
      color: $white;
    }
  }

  .labelsContent {
    .nameText {
      color: $white;
    }

    .emailText {
      color: $title-item-dark;
    }
  }
}

.profileBottomContainerLight {
  .avatar {
    background-color: $white;
    border: 2px solid $chinese-white-1;

    .avatarLabel {
      color: $dark-silver;
    }
  }

  .labelsContent {
    .nameText {
      color: $black;
    }

    .emailText {
      color: $title-item-light;
    }
  }
}

@media (max-width: 660px) {
  .profileBottomContainer {
    display: none;
  }
}

:global {

  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: $white;
  }

  .ant-menu-dark .ant-menu-item:hover {
    span {
      color: $title-item-dark;
    }
  }

  .ant-menu-submenu-placement-rightTop {
    left: 88px !important;

    .ant-menu-title-content {
      color: $title-item-light;
      font-size: 13px;
    }
  }

  .ant-menu-submenu-placement-rightTop {
    .ant-menu-item-only-child {
      height: 32px;
      line-height: 32px;
    }
  }

  .ant-menu-item-selected {
    .ant-menu-title-content {
      color: $title-item-dark;
    }
  }

  .ant-menu-inline-collapsed-tooltip {
    left: 74px !important;

    .ant-tooltip-content {
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: transparent;
        background-color: transparent;
      }

      .ant-tooltip-inner {
        background-color: $background-light;
        color: $title-color-light;
      }
    }
  }
}

.menu {
  background-color: red;
}

.menuTitle {
  cursor: auto;
  border-bottom: 1px solid $divider-color;
  margin-bottom: 0px !important;

  span {
    font-size: 12px;
    color: $dark-charcoal-3 !important;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.subItem {
  margin-bottom: 0px !important;
}