@use '../../../../common/styleConstants.scss' as *;

.container {
  width: 100%;
  padding: 0 15px;
  display: flex;

  .weightUnit {
    display: flex;
    flex-direction: column;

    div {
      margin: 8px 0 0 18px;
    }
  }
}