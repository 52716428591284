@use '../../../../src/common/styleConstants.scss' as *;

.content {
  height: 100%;

  .calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .cell {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100px;
      color: $white;

      .day {
        position: absolute;
        color: $black;
        top: 0px;
        right: 2px;
      }

      .count {
        color: $black;
        font-size: 38px;
        text-align: center;
      }
    }

    .cell {
      border-top: 1px solid $chinese-white;
      border-left: 1px solid $chinese-white;
    }
  
    tr:last-child .cell {
      border-bottom: 1px solid $chinese-white;
    }
  
    td:last-child .cell  {
      border-right: 1px solid $chinese-white;
    }
  }
}
