@use '../../../common/styleConstants.scss' as *;

.container {
  width: 200px;
  height: 100%;
  margin-top: 4px;
}

.tanks {
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }

  .tank {
    &:hover {
      cursor: pointer;
    }
  }

  .tankSelected {
    &:hover {
      cursor: pointer;
    }
  }
}

.tanksDark {
  .tank {
    color: $cultured;

    &:hover {
      background-color: $btn-bg-hover;
    }
  }

  &::-webkit-scrollbar {
    border-left: 1px solid $dropdown-border-color !important;
  }

  .tankSelected {
    color: $cultured;

    &:global.ant-list-item {
      border-left: 6px solid $primary-color-dark;
      padding-left: 10px;
    }

    &:hover {
      background-color: $btn-bg-hover;
    }
  }
}

.tanksLight {
  .tank {
    color: $top-bar-icon;

    &:hover {
      background-color: $btn-bg-hover-light;
    }
  }

  &::-webkit-scrollbar {
    border-left: 1px solid $dropdown-border-color-light !important;
  }

  .tankSelected {
    color: $top-bar-icon;

    &:global.ant-list-item {
      border-left: 5px solid $primary-color-light;
      padding-left: 11px;
    }

    &:hover {
      background-color: $btn-bg-hover-light;
    }
  }
}

.tanksTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  .title {
    font-size: 14px;
    margin-top: 2px;
  }

  .icons {
    display: flex;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2px;
      margin-left: 6px;
      width: 24px;
      height: 24px;

      i {
        transition: 0.2s;
      }
    }

    .icon:not(.disabled):hover {
      i {
        transition: 0.2s;
        color: $sonic-silver;
      }
    }

    .disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}

.dropdownContainerDark {
  .icon {
    background-color: $indigo;
  }
}

.dropdownContainerLight {
  .icon {
    background-color: $lavander-blue;
  }
}

.analysisCountDark {
  width: max-content;
  background-color: $black;
  border-radius: 9px;
  border: 1px solid $raising-black;
  margin-top: 1px;
  align-self: flex-end;
}

.analysisCountLight {
  width: fit-content;
  background-color: $sider-background-light;
  border-radius: 9px;
  border: 1px solid $chinese-silver-c;
  margin-top: 1px;
}