@use '../../../common/styleConstants.scss' as *;

.chartModal {
  top: 20px;
  
  .container {
    position: relative;
  
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form {
        display: flex;
        align-items: flex-start;
      }

      .select, .inputNumber {
        width: 200px;
      }

      .legend {
        display: flex;
        align-items: center;
        
        span {
          font-size: 13px;
        }

        .square {
          width: 24px;
          height: 12px;
        }

        .circle {
          width: 14px;
          height: 14px;
          border-radius: 16px;
        }
  
        .error {
          background-color: #ff00001a;
          border: 2px solid #ff000080;
        }
  
        .warning {
          background-color: #ffaa001a;
          border: 2px solid #ffaa0080;
        }

        .cost {
          background-color: #43A0471a;
          border: 2px solid #43A04780;
        }

        .cost {
          background-color: $may-green;
        }

        .costPerDay {
          background-color: #3F9AE9;
        }
      }
    }
  
    .button {
      float: right;
    }
  
    .chart {
      position: relative;
    }
  
    .labelAxisY {
      font-size: 0.85em;
    }
    
    .labelAxisX {
      text-align: center;
      font-size: 0.85em;
    }

    .slider {
      flex: 1;
    }
  
    .excludeActions {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .actionButton {
        width: 90px;
      }
  
      .applyButton {
        border-radius: 6px 0px 0px 6px;
      }
  
      .cancelButton {
        border-radius: 0px 6px 6px 0px;
        border: 1px solid $primary-color-light;
      }
    }
  }
}