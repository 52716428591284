
.table {
  tbody tr td div div input {
    font-size: 13px;
  }

  .input {
    div {
      input {
        height: inherit !important;
        padding: 0 6px;
      }
    }
  }
}