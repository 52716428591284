@use '../../../common/styleConstants.scss' as *;

.container {
  position: relative;
  width: 100%;

  .progress {
    position: relative;
    height: 20px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    align-items: center;
    padding: 2px;
    transition: all 0.3s;

    .containerBiomass {
      position: absolute;
      white-space: nowrap;
      left: 8px;
      font-weight: 500;
      margin-top: 1px;

      .biomass {
        font-size: 13px;
        color: $black;
      }
  
      .biomassUnit {
        font-size: 13px;
        color: $black;
      }
    }

    .outside {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(5px, -50%);
    }
  }
}

.lightContainer {
  .progress {
    .outside {
      span {
        color: $black !important;
      }
    }
  }
}

.darkContainer {
  .progress {
    .outside {
      span {
        color: $title-color-dark !important;
      }
    }
  }
}

