@use '../../../common/styleConstants.scss' as *;

.container {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  border-radius: 4px;

  .slider {
    position: absolute;
    top: 9px;
    left: 0;
    right: 0;
    height: 10px;
    background-color: $alice-blue;
    transform: translateY(-50%);
    border-radius: 8px;
    border: 1px solid $bright-gray;
  }

  .containerSquare {
    position: absolute;
    width: 18px;
    height: 18px;
  }
  
  .square {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    cursor: grab;
    background-color: $cornflower-blue;
  }

  .text {
    text-align: center;
    font-size: 11px;
    color: black;
    user-select: none;
    white-space: nowrap;
  }
}
