@use '../../../common/styleConstants.scss' as *;

.editPacker {
  .switch {
    display: flex;
    justify-content: flex-end;
  
    .status {
      margin-right: 8px;
    }
  }
}
