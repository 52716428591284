@use '../../common/styleConstants.scss' as *;

.table {
  margin: 0 5px;

  :global {
    .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
      padding: 4px 8px;
      font-size: 13px;
    }
  }

  .mainRow {
    background-color: #D9FFDD !important;
    border: 1px solid #74C484;
  }

  .secondaryRow {
    background-color: #F6F6F6 !important;
    border: 1px solid #DADADA;
  }

  .containerCircle {
    display: flex;
    align-items: center;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      border: 2px solid $black;

      display: flex;
      justify-content: center;

      span {
        margin-top: -1px;
        font-size: 12px !important;
      }
    }
  }

  .containerOptions {
    display: flex;
    align-items: center;

    .value {
      font-size: 13px !important;
    }
  
    .button {
      background-color: transparent;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;

      span {
        font-size: 12px !important;
      }
    }

    &:hover {
      .value {
        text-decoration: underline;
      }

      .button { 
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }
    }
  }
}

.tableDark {
  .containerCircle {
    .circle {
      span {
        color: $bright-gray !important;
      }
    }
  }
}