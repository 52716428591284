@use '../../../../src/common/styleConstants.scss' as *;

.archivedStocking {
  width: 100%;
  height: 90%;

  .rowHeader {
    width: 100%;
    height: 70px;
    margin-top: -10px;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      width: 160px;
    }

    .sidePanel {
      display: none;
    }
  }

  @media (max-width: 620px) {
    .rowHeader {
      align-items: center;

      .filters {
        display: none;
      }

      .sidePanel {
        display: flex;

        .title {
          color: $title-color-light;
          font-size: 16px;
          font-weight: bold;
        }

        .bodyPanel {
          width: 100%;
        }
      }
    }
  }

  .analysisCountDark {
    background-color: $black;
    border-radius: 9px;
    border: 1px solid $raising-black;
  }

  .analysisCountLight {
    background-color: $sider-background-light;
    border-radius: 9px;
    border: 1px solid $chinese-silver-c;
  }

  th.sortColumnCellDark {
    cursor: pointer;

    &:hover {
      background-color: $row-selected-dark !important;
    }
  }

  th.sortColumnCellLight {
    cursor: pointer;

    &:hover {
      background-color: $row-selected-light !important;
    }
  }

  .table {
    :global {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 290px);
          overflow-y: scroll;
        }
      }
    }
  }
}