@use '../../common/styleConstants.scss' as *;

.companyStockingParameterForm {
  .title {
    display: flex;
    margin: 4px 0 10px 0;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    column-gap: 10px;
    row-gap: 20px !important;

    .gridItem {
      border-radius: 6px;
      border: 1px solid $gray;
      padding: 4px;
      text-align: center;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .itemSelected {
      background-color: $green-screamin;
      border-color: $active-status-color;
    }
  }

  .parameterDateContainer {
    margin-bottom: 20px;
  }

  .containerStartButton {
    margin: 20px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}