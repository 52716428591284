@use '../../common/styleConstants.scss' as *;

.emptyContainer,
.spinnerContainer {
  position: absolute;
  width: calc(100% - 25px);
  height: calc(100vh - 84px);
  z-index: 999;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 184px;

    i {
      font-size: 40px;
    }
  }

  .center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    height: 100%;
  }
}

.alertContainer {
  position: absolute;
  z-index: 9;
  height: calc(100vh - 126px);
  width: 100%;

  .alert {
    top: calc(50% - 20px);
    justify-self: center;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 4px 15px;

    :global .ant-alert-content {
      flex: unset;
    }
  }
}

.lightContainer {
  background-color: $body-background-light;
}

.darkContainer {
  background-color: $body-background-dark;
}

.optimalHarvestPoint {
  margin-top: -15px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  height: calc(100vh - 80px);
  overflow-y: scroll;

  .chartHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .lightText {
      color: $davys-grey-5;
      font-size: 14px;
    }

    .darkText {
      color: $bright-gray;
      font-size: 14px;
    }

    .hideRightLabel {
      opacity: 0,
    }
  }

  .body {
    display: flex;
    flex-direction: row;

    .chart {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      .stockingContainer {
        margin: 0px 14px 12px 14px;
      }
      
      .stocking {
        display: flex;
  
        .name {
          font-size: 18px;
          font-weight: bold;
        }
  
        .icon {
          font-size: 20px;
        }
      }

      .chartContainer {
        display: flex;
      }
    }

    .inputsColumn {
      border-radius: 8px;

      .inputsContainer {
        overflow-y: auto;
        height: calc(100vh - 84px);
        padding: 6px 4px 6px 4px;

        .inputContainer {
          width: 170px;

          .readOnly {
            border: none;
          }
        }
      }
    }

    .chartColumn {
      display: flex;
      flex-direction: column;

      .predictionsChart {
        margin: 0px 14px 0px 14px;

        .container {
          border-radius: 8px;

          .options {
            display: flex;
            justify-content: space-between;
            margin-right: 9px;
          }

          .actions {
            .valueType {
              .radioButton {
                height: 28px;
                line-height: 26px;
                background-color: transparent;
                padding: 0 10px;
        
                span {
                  div {
                    font-size: 12px;
                  }
                }
              }

              .radioButtonIcon {
                height: 28px;
                background-color: transparent;
        
                span {
                  div {
                    font-size: 11px;
                  }
                }
              }
        
              .radioButtonDark {
                border: 2px solid $philippine-gray;
        
                &:not(.radioSelected):hover {
                  background-color: $btn-bg-hover;
                }
        
                span {
                  div, i {
                    color: $bright-gray;
                  }
                }
              }

              .radioButtonDark.radioSelected {
                border: 2px solid $blue;
        
                span {
                  div {
                    transition: 0.3s;
                    font-weight: 500;
                  }

                  div, i {
                    transition: 0.3s;
                  }
                }
              }

              .radioButtonLight {
                border: 2px solid $chinese-silver-c;
        
                &:not(.radioSelected):hover {
                  background-color: $btn-bg-hover-light;
                }
        
                span {
                  div, i {
                    color: $dark-charcoal-3;
                  }
                }
              }
        
              .radioButtonLight.radioSelected {
                border: 2px solid $blue;
                background-color: $lotion;
        
                span {
                  div {
                    transition: 0.3s;
                    color: $blue;
                    font-weight: 500;
                  }

                  i {
                    transition: 0.3s;
                    color: $blue;
                  }
                }
              }

              .leftOption {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                border-right-width: 0px;
              }
        
              .rightOption {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left-width: 0px;
              }
            }
          }

          .table {
            padding-top: 12px;
          }
        }
      }
      
      .indicators {
        margin: 0 12px 12px 12px;
        display: flex;
        justify-content: space-between;

        .options {
          display: flex;
          align-items: center;
  
          .detailHarvest {
            border-radius: 6px;
            padding: 0 10px;
            border: 1px solid $chinese-silver;
            cursor: pointer;

            &:hover {
              background-color: $lotion;
            }
            
            .icon {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .rowHeader {
    margin-bottom: 12px;

    .excludeActions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .actionButton {
        width: 90px;
      }

      .applyButton {
        border-radius: 6px 0px 0px 6px;
      }

      .cancelButton {
        border-radius: 0px 6px 6px 0px;

        &.lightCancelButton {
          border: 1px solid $primary-color-light;
        }

        &.darkCancelButton {
          border: 1px solid $primary-color-dark;
        }
      }
    }
  }

  .rowBody {
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  .hideChart {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }

  .showChart {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    height: auto;
  }

  .container {
    height: 100%;

    .labelAxisX {
      text-align: center;
      font-size: 1em;
    }

    .axisLight {
      color: $davys-grey-5;
    }

    .axisDark {
      color: $quick-silver;
    }

    .padding {
      height: 40px;
    }

    .slider {
      margin-right: 24px;
      margin-left: 60px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;

  div, strong {
    font-size: 12px;
  }
}