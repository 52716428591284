@use '../../../common/styleConstants.scss' as *;

.descriptions {
  :global {
    .ant-descriptions-row > th, .ant-descriptions-row > td {
      padding-bottom: 8px;
    }
  }
}

.lightDescriptions {
  :global {
    .ant-descriptions-title {
      color: $title-color-light;
      font-weight: bold;
    }

    .ant-descriptions-view
      .ant-descriptions-row
      .ant-descriptions-item
      .ant-descriptions-item-container {
      .ant-descriptions-item-label {
        color: $title-color-light;
        font-weight: bold;
      }

      .ant-descriptions-item-content {
        color: $top-bar-icon;
      }
    }
  }
}

.darkDescriptions {
  :global {
    .ant-descriptions-title {
      color: $title-color-dark;
    }

    .ant-descriptions-view
      .ant-descriptions-row
      .ant-descriptions-item
      .ant-descriptions-item-container {
      .ant-descriptions-item-label {
        color: $title-color-dark;
      }

      .ant-descriptions-item-content {
        color: $gray-x11;
      }
    }
  }
}
