@use '../../../styleConstants.scss' as *;

.triangle {
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid $davys-grey-5;
}

.axisXValue {
  font-size: 12px;
}

.axisXValueLight {
  fill: $davys-grey-5;
}

.axisXValueDark {
  fill: $philippine-gray;
}

.tooltipResetZoom,
.tooltipMedalIcon {
  position: absolute;
  z-index: 9;
  opacity: 0;
  background-color: whitesmoke;
  font-size: 13px;
  color: #000;
  border-radius: 8px;
  padding: 6px 8px;
  width: auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent whitesmoke;
    display: block;
    width: 0;
    z-index: 1;
    left: unset;
    right: -12px;
    top: calc(50% - 6px);
  }
}

.tooltip {
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transition: 250ms ease-in-out;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;

  &.rightAlignedTooltip {
    &:before {
      -webkit-transform: rotate(135deg) skew(15deg, 15deg);
      transform: rotate(135deg) skew(15deg, 15deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      left: unset;
      right: -8px;
    }

    &:after {
      left: unset;
      right: -16px !important;
      border-width: 8px 0 8px 16px;
    }
  }

  &.leftAlignedTooltip {
    &:before {
      -webkit-transform: rotate(45deg) skew(25deg, 25deg);
      transform: rotate(135deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      top: calc(50% - 8px);
      left: -8px;
      right: unset;
    }
  }

  .extraPadding {
    bottom: 0px;
    position: absolute;
    background-color: transparent;
    height: 100%;
  }

  .content {
    background-color: white;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 160px;
    max-width: 200px;
    padding: 8px 10px;

    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    hr {
      border: 0;
      height: 1px;
      background: #d5d5d5;
      margin: 0;
    }

    .entry {
      color: black;
    
      .entryHeader {
        width: 100%;

        .entryTitle {
          font-size: 11.5px;
        }
      }

      .radioEntry {
        margin-top: 4px;
        
        .radioLabel {
          display: flex;
          cursor: pointer;
          align-items: center;
          gap: 8px; 

          .radioInput {
            display: none;
          }
          
          .radioCustom {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid transparent;
            transition: all 0.2s ease-in-out;
          }
          
          .radioInput:checked + .radioCustom {
            position: relative;
          }
          
          .radioInput:checked + .radioCustom::after {
            content: "";
            top: 0.5px;
            width: 14px;
            height: 15px;
            background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.375L9.75 17 4.5 11.625l1.875-1.875L9.75 13.25l8.625-8.625z"/></svg>');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
          }
  
          .selectedPocPoint {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            text-align: center;
            border: 1px solid transparent;
            font-weight: bold;
            font-size: 11.5px;
            line-height: 1.45;
            transition: all 0.2s ease-in-out;
          }

          .radioText {
            font-size: 11.5px;
            cursor: pointer;

            &:hover {
              text-decoration: underline dotted;
            }
          }

          .selectedPacker {
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    left: -16px;
    top: calc(50% - 6px);
  }
}

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 4;
    stroke-width: 0.5;
  }

  text {
    font-size: 0.8em;
  }
}

.axisY {
  line,
  path {
    stroke: transparent;
  }

  text {
    font-size: 0.8em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.hideCircle {
  display: none;
}

.circle {
  stroke-width: 2;
}

.currencySymbol {
  text-anchor: middle;
  font-size: 14px;
  font-weight: bold;
}

.moon {
  filter: grayscale(100%) contrast(100%);
}