@use '../../common/styleConstants.scss' as *;

.divider {
  margin: 0px !important;
  background-color: $light-silver !important;
}

.header {
  padding: 24px 12px;
  font-size: 16px;
  color: $title-color-light;
  font-weight: bold;
}

.emptyContainer {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    color: $davys-grey-5;
  }
}

.item {
  :global {

    .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child,
    .ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child {
      background-color: $body-background-light;

      &:hover {
        background-color: $dropdown-hover-color-light;
      }
    }
  }

  li {
    padding: 8px 12px 10px 12px !important;
    margin: 0px !important;
    height: auto !important;
  }

  &:hover {
    background-color: $dropdown-hover-color-light !important;

    .avatar {
      background-color: $white;
    }
  }
}

.containerItem {
  display: flex;
  align-items: center;

  .space {
    background-color: transparent;
  }

  .avatar {
    background-color: $bright-gray;
  }

  .planIcon {
    position: relative;
    top: -1px;
    font-size: 18px;
  }

  .arrowIcon {
    font-size: 14px;
    padding: 0 4px;
    color: $sonic-silver;
  }

  .column {
    position: relative;
    top: 2px;
    display: flex;
    margin-left: 2px;
    flex-direction: column;

    .row {
      line-height: 16px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      color: $title-color-light;
      width: 290px !important;
      padding-bottom: 2px;
    }

    .space {
      font-size: 12px;
      width: 16px;
    }

    .label {
      font-size: 12px;
      font-weight: 100;
      width: 82px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .labelStocking {
      font-size: 12px;
      font-weight: 100;
      width: 290px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.containerNotifications {
  width: 360px;
  border-radius: 12px;
  border: 1px solid $light-silver;
  background-color: $body-background-light;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);

  .divider {
    margin: 0px !important;
  }

  :global {
    .infinite-scroll-component {
      &::-webkit-scrollbar {
        border-left: 1px solid $light-silver;
      }
    }
  }
}

.menu {
  width: 100% !important;
  border: none;

  .submenu {
    :global {
      .ant-menu-submenu-title {
        display: table-cell;
        vertical-align: middle;
        padding: 0px 14px !important;

        &:active {
          background-color: transparent;
        }

        .ant-menu-title-content {
          padding: 6px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;

          :local .noBadgeContainer {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: #dedede;
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: "tnum", "tnum";
            position: relative;
            display: inline-block;
            line-height: 1;
          }
        }
      }
    }
  }

  .badge {
    >sup {
      box-shadow: none;
      background-color: red;
      width: 10px;
      height: 10px;

      >span {
        height: 0px;

        >span {
          font-size: 12px;
          position: relative;
        }
      }
    }
  }

  .bellIcon {
    font-size: 20px;
  }
}

.menuDark {
  :global {
    .ant-menu-submenu {
      transition: 0s;
      background-color: $body-background-dark;

      .ant-menu-submenu-title .ant-menu-title-content:hover {
        background-color: $btn-bg-hover;
      }
    }
  }
}

.menuLight {
  border: none;

  :global {
    .ant-menu-submenu {
      transition: 0s;
      background-color: $body-background-light;

      .ant-menu-submenu-title .ant-menu-title-content:hover {
        background-color: $btn-bg-hover-light;
      }
    }
  }
}