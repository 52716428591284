@use '../../../common/styleConstants.scss' as *;

.container {
  width: 190px;
  background-color: $lotion;
  border: 2px solid $sider-border-light;
  border-radius: 5px;
  overflow-y: scroll;
  padding-top: 6px;
  margin-bottom: 12px;
  
  .title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;
  }
  
  .packer {
    margin: 14px 0;
    position: relative;
  }
  
  .packerInfo {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    color: $black;
    cursor: pointer;
    
    .packerName {
      font-size: 13px;
      margin-left: 6px;
      font-weight: 500;
    }
    
    .iconContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      
      border-radius: 16px;
      width: 20px;
      height: 20px;
      right: 0;

      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      .icon {
        position: relative;
        top: -2px;
        font-size: 15px;
      }
    }

    &:hover {
      .iconContainer {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    }
  }
}

.lightContainer {
  background-color: $lotion;
  border: 2px solid $sider-border-light;

  .iconContainer {      
    background-color: $white;
  }
}

.darkContainer {
  background-color: $sider-background-dark;
  border: 2px solid $sider-border-dark;

  .iconContainer {      
    background-color: $sider-background-dark;
  }
}
