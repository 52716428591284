@use '../../../common/styleConstants.scss' as *;

.container {
  .value {
    color: $black;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;

    .icon {
      font-size: 16px;
    }

    @media (max-width: 1280px) {
      font-size: 13px;
    }

    @media (max-width: 1160px) {
      font-size: 11px;
    }
  }
  
  .label {
    color: #747474;
    font-size: 13px;

    @media (max-width: 1280px) {
      font-size: 11px;
    }

    @media (max-width: 1160px) {
      font-size: 10px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
