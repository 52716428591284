@use '../../../common/styleConstants.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  border-radius: 6px;
  cursor: text;

  :global {
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
      border: 1px solid transparent; 
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      background-color: transparent;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent;
    }

    .ant-select-selector {
      border: 1px solid $black;
    }
  }

  &:hover {
    box-shadow: 0 0 0 1px $black;
    border: 1px solid $black;
  }

  .titleUnfocused {
    transform: translateY(60%);
  }
}

.disabled {
  cursor: not-allowed;
}

.darkContainer {
  background-color: $sider-background-dark;
  border: 1px solid $sider-background-dark;

  .darkTitle {
    color: $dark-gray-x11;
    font-size: 11px;
    padding-left: 10px;
    transition: all 0.3s ease;
    pointer-events: none;
    letter-spacing: 0.15px;
  }

  .darkSelect {
    border: 1px solid transparent;

    :global {
      .ant-select-selector {
        transition: 0s;
        background-color: transparent;
        border: 1px solid transparent;

        .ant-select-selection-item {
          transition: 0s;
          color: $bright-gray;

          i {
            color: inherit;
          }
        }

        &:hover {
          border: 1px solid transparent;
        }
      }

      .ant-select-focused {
        border: 1px solid transparent;
      }

      .ant-select-arrow i {
        position: relative;
        top: -10px;
        color: $title-color-dark;
      }
    }
  }

  .darkSelect.disabled {
    :global {
      .ant-select-selector {
        transition: 0s;
      }

      .ant-select-selection-placeholder {
        color: $sonic-silver;
      }
    }
  }

  :global {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: $bright-gray;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: 0 0 0 1px transparent;
      border: 1px solid transparent;
    }

    .ant-select-multiple {
      .ant-select-selection-item {
        background: red;
        border: 1px solid red;

        .ant-select-selection-item-content {
          color: $bright-gray;
        }

        .ant-select-selection-item-remove {
          span {
            color: $bright-gray;
          }
          i {
            color: $bright-gray;
          }
        }
      }
    }
  }
}

.lightContainer {
  background-color: $lotion;
  border: 1px solid $lotion;

  .lightTitle {
    color: $davys-grey-5;
    font-size: 11px;
    padding-left: 10px;
    transition: all 0.3s ease;
    pointer-events: none;
    letter-spacing: 0.1px;
  }

  .lightSelect {
    border: 1px solid transparent;

    :global {
      .ant-select-selector {
        transition: 0s;
        border: 1px solid transparent;
        background-color: transparent;

        outline: none !important;
        box-shadow: none !important;

        .ant-select-selection-item {
          transition: 0s;
          color: $dark-charcoal-3;

          i {
            color: inherit;
          }
        }

        &:hover {
          border: 1px solid transparent;
        }
      }

      .ant-select-arrow i {
        position: relative;
        top: -10px;
        color: $spanish-gray;
      }

      .ant-select-selection-search-input {
        color: $dark-charcoal-3;
      }
    }
  }

  .lightSelect.disabled {
    :global {
      .ant-select-selector {
        transition: 0s;
        border: 1px solid transparent;
        background-color: transparent;

        .ant-select-selection-placeholder {
          color: $disabled-color;
        }
      }
    }
  }

  :global {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: 0 0 0 1px transparent;
      border: 1px solid transparent;
    }

    .ant-select-multiple {
      .ant-select-selection-item {
        background: red;
        border: 1px solid red;

        .ant-select-selection-item-content {
          color: $title-color-light;
        }

        .ant-select-selection-item-remove {
          span {
            color: $title-color-light;
          }
          i {
            color: $title-color-light;
          }
        }
      }
    }
  }
}

.darkPopup {
  background-color: $body-background-dark;

  :global {
    .rc-virtual-list-holder .rc-virtual-list-holder-inner {
      .ant-select-item-option-selected {
        background-color: $body-background-dark;
      }
    }
  }
}

.lightPopup {
  background-color: $body-background-light;

  :global {
    .ant-select-item-group {
      color: $dark-charcoal-3;
    }

    .rc-virtual-list-holder .rc-virtual-list-holder-inner {
      .ant-select-item.ant-select-item-option {
        background-color: $body-background-light;

        .ant-select-item-option-content {
          color: $dark-charcoal-3;
          i {
            color: $dark-charcoal-3;
          }
        }
      }

      .ant-select-item-option-selected {
        background-color: $body-background-light;
      }

      .ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
        background-color: $dropdown-hover-color-light;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: $body-background-light;
      }
    }

    .ant-select-dropdown {
      background-color: $body-background-light;
    }
  }
}

.focus {
  box-shadow: 0 0 0 1px $black;
  border: 1px solid $black;
}