@use '../../styleConstants.scss' as *;

.avatar {
  background-color: yellow;
  width: 35px;
  height: 35px;
  color: $raisin-black;
  font-weight: bold;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-radius: 35px;
  cursor: pointer;
  border: 2px solid transparent;

  .textNameAvatar {
    font-size: 0.55em;
    color: $white;
  }
}