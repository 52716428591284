@use '../../../../common/styleConstants.scss' as *;

.container {
  background-color: white;
  margin: auto;
  

  .transferData {
    display: flex;
    flex-direction: column;
    margin: 12px 40px 0px 40px;

    .transferName {
      font-size: 1.2em;
      color: $davys-grey;
      font-weight: bold;
      margin-top: 12px;
    }
  
    .row {
      display: flex;
      justify-content: space-around;
      background-color: $ghost-white;
      border-radius: 6px;
      padding: 4px 0;
      margin: 9px 0;
  
      .gridItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .value {
          font-size: 1.1em;
          color: $davys-grey;
          text-align: center;
          font-weight: bold;
        }
        
        .field {
          font-size: 0.95em;
          color: $quick-silver;
          text-align: center;
        }        
      }
    }
  }

  .histogram {
    margin: 20px 40px 0 40px;
  }
}
