@use './../../styleConstants.scss' as *;

.container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white-40;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow: auto;

  .modal {
    background-color: $white;
    border-radius: 10px;
    margin: 8% auto;
    width: 35vw;
    line-height: normal;
    border: 1px solid $sonic-silver;

    .header {
      padding: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      justify-content: space-between;

      .title {
        width: 100%;
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        color: $primary-color-dark;
      }
    }

    .body {
      padding: 5px 40px 15px 40px;
      color: black;
    }

    .footer {
      padding: 0 40px 25px 40px;
      display: flex;

      .secondaryButton {
        color: black;
      }

      .secondaryButton:hover {
        color: white;
      }
    }
  }

  @media (max-width: 1600px) {
    .modal {
      width: 40vw !important;
    }
  }

  @media (max-width: 1200px) {
    .modal {
      width: 50vw !important;
    }
  }

  @media (max-width: 860px) {
    .modal {
      width: 60vw !important;
    }
  }

  @media (max-width: 860px) {
    .modal {
      width: 70vw !important;

      .header {
        padding: 15px;

        .title {
          font-size: 1.4em;
        }
      }

      .body {
        padding: 5px 30px 20px 30px;
      }

      .footer {
        padding: 0 30px 20px 30px;
      }
    }
  }

  @media (max-width: 420px) {
    .modal {
      width: 80vw !important;

      .header {
        padding: 15px;

        .title {
          font-size: 1.2em;
        }
      }
    }
  }
}
