@use '../../../styleConstants.scss' as *;

.triangle {
  position: absolute;
  top: 0px;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid $davys-grey-5;
}

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 2.5;
    stroke-width: 0.5;
  }

  text {
    font-size: 0.8em;
  }
}

.axisY {
  line,
  path {
    stroke: transparent;
  }

  text {
    font-size: 0.8em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.line {
  stroke: #43A047;
  fill: transparent;
  stroke-width: 2;
  stroke-linecap: round;
}

.circle {
  cursor: grab;

  &:hover {
    stroke: #126315;
  }
}
