@use '../../common/styleConstants.scss' as *;

.container {
  margin: 0 1.8em;

  .options {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    padding-bottom: 24px;

    &::-webkit-scrollbar-thumb {
      background: $light-silver !important;
    }

    .menuItem {
      padding: 6px 0px;

      .item {
        height: 40px;
      }
    }

    .item,
    .geneticsItem,
    .productionItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 52px;

      i {
        font-size: 24px;
      }

      :global .ant-switch-inner {
        i {
          font-size: 16px;
        }
      }

      .subMenuArrow {
        position: absolute;
        right: 10px;
        align-self: center;
        transition: 0.3s;
      }

      .itemTitle {
        font-size: 14px;
        padding-left: 12px;
      }
    }

    .itemSelected {
      background-color: $item-selected-dark;
      border-radius: 5px;
      transition: 0.3s;
    }

    :global .ant-collapse-item-active :local {
      .subMenuArrow {
        transform: rotate(180deg);
        transition: 0.3s;
      }

      .itemSelected {
        background-color: $body-background-dark;
      }
    }

    .productionItem svg {
      width: 26px;
    }

    .geneticsItem svg {
      width: 24px;
    }

    .hand {
      cursor: pointer;
      height: 52px;
    }

    :global .ant-collapse {
      border: none;

      .ant-collapse-item {
        border: none;
        background-color: $body-background-dark;

        .ant-collapse-header {
          background-color: $body-background-dark;
          padding: 0px;
        }

        .ant-collapse-content {
          border: none;
          background-color: $body-background-dark;

          .ant-collapse-content-box {
            border-left: 1px solid $submenu-border-color;
            margin-left: 20px;
            padding: 5px 16px;
            background-color: $body-background-dark;

            :local .item {
              padding: 2px 0px;
              margin: 0px;
            }

            :local .itemSelected {
              background-color: $item-selected-dark;
              border-radius: 5px;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}

.containerDark {
  .options {
    .subMenuArrow {
      color: $menu-arrow-color-dark;
    }

    .itemSelected {
      background-color: $item-selected-dark;
    }

    .item,
    .geneticsItem,
    .productionItem {
      color: $title-item-dark;
    }

    :global .ant-collapse-item-active :local {
      .itemSelected {
        background-color: $body-background-dark;
      }
    }

    :global .ant-collapse {
      .ant-collapse-item {
        background-color: $body-background-dark;

        .ant-collapse-header {
          background-color: $body-background-dark;
        }

        .ant-collapse-content {
          background-color: $body-background-dark;

          .ant-collapse-content-box {
            border-left: 1px solid $submenu-border-color-dark;
            background-color: $body-background-dark;

            :local .itemSelected {
              background-color: $item-selected-dark;
            }
          }
        }
      }
    }
  }
}

.containerLight {
  .options {
    .subMenuArrow {
      color: $menu-arrow-color-light;
    }

    .item.itemSelected {
      background-color: $item-selected-light;
      color: $title-item-hover-light;

      i {
        color: $title-item-hover-light;
      }
    }

    .itemSelected.productionItem {
      color: $title-item-hover-light;

      svg g {
        path,
        circle {
          stroke: $title-item-hover-light;
        }
      }
    }

    .itemSelected.geneticsItem {
      color: $title-item-hover-light;
      svg g path {
        fill: $title-item-hover-light;
      }
    }

    :global .ant-collapse-item-active :local {
      .itemSelected {
        background-color: $body-background-light;
        color: $title-item-light;

        i {
          color: $title-item-light;
        }
      }

      .subMenuItem .itemSelected .itemTitle {
        color: $title-item-hover-light;
      }

      .itemSelected.productionItem {
        color: $title-item-light;
        
        svg g {
          path,
          circle {
            stroke: $title-item-light;
          }
        }
      }

      .itemSelected.geneticsItem {
        color: $title-item-light;
        svg g path {
          fill: $title-item-light;
        }
      }
    }

    .item,
    .geneticsItem,
    .productionItem {
      color: $title-item-light;

      :global .ant-switch-inner {
        i {
          color: $white;
        }
      }

      i {
        color: $title-item-light;
      }
    }

    .productionItem svg g {
      path,
      circle {
        stroke: $title-item-light;
      }
    }

    .geneticsItem svg g path {
      fill: $title-item-light;
    }

    :global .ant-collapse-item-active :local {
      .itemSelected {
        background-color: $body-background-light;
      }
    }

    :global .ant-collapse {
      .ant-collapse-item {
        background-color: $body-background-light;

        .ant-collapse-header {
          background-color: $body-background-light;
        }

        .ant-collapse-content {
          background-color: $body-background-light;

          .ant-collapse-content-box {
            border-left: 1px solid $submenu-border-color-light;
            background-color: $body-background-light;

            :local .itemSelected {
              background-color: $item-selected-light;
            }
          }
        }
      }
    }
  }
}
