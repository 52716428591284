@use '../../../common/styleConstants.scss' as *;

.heatMapModal {
  top: 20px;
  max-width: 820px;

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .datePicker {
      width: 140px;
    }

    .containerHeatMap {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .cell {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 100%;
        height: 100px;
        color: $white;

        .day {
          position: absolute;
          color: $black;
          top: 0px;
          right: 2px;
        }

        .count {
          color: $black;
          font-size: 38px;
        }

        .emoji {
          position: absolute;
          font-size: 14px;
          bottom: 0px;
          right: 2px;
          filter: grayscale(100%) contrast(100%);
        }
      }
    
      .cell {
        border-top: 1px solid $chinese-white;
        border-left: 1px solid $chinese-white;
      }
    
      tr:last-child .cell {
        border-bottom: 1px solid $chinese-white;
      }
    
      td:last-child .cell  {
        border-right: 1px solid $chinese-white;
      }
    
    }
  }
  
  .heatMap {
    width: 506px;
    height: 520px;

    .day {
      font-size: 26px;
    }

    .group {
      cursor: pointer;
    }
  }

  .legend {
    position: relative;

    .title {
      position: absolute;
      white-space: nowrap;
      top: -5px;
      left: -12px;

      span {
        font-size: 18px;
      }
    }
  }

  :global {
    .w-heatmap {
      font-size: 18px !important;

      .w-heatmap-week {
        transform: translate(-4px, -22px);

        @media (max-width: 820px) {
          transform: translateY(-16px);
        }
      }
    }
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  text-align: left;

  span {
    font-size: 12px;
  }

  .label {
    font-weight: bold;
  }
}