@use '../../../common/styleConstants.scss' as *;

.priceTable {
  top: 20px;
  .packerName {
    font-size: 20px;
  }

  .isDifferentDate {
    font-size: 14px;
    color: $orange-red;
  }
}