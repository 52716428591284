@use '../../../common/styleConstants.scss' as *;

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  width: 190px;
  
  .containerHeatMap {
    position: relative;
    width: 100%;
    padding-right: 8px;

    .month {
      font-size: 11px; 
    }

    .calendar {
      :global {
        .ant-picker-body {
          padding: 0;
        }
      }

      tr {
        th {
          width: auto;
          font-size: 10px;
          padding: 0;
          text-align: center;
        }
      }
  
      .cell {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 100%;
        height: 100%;
  
        .day {
          position: absolute;
          top: 0px;
          right: 2px;
          font-size: 9px;
        }
      }
    
      tr {
        td {
          height: 25px;
        }
      }
    }
  }

  .containerChart {
    padding-top: 39px;
  }

  .chart {
    max-height: 156px;
    overflow: hidden;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 26px;
    
    background-color: $white-bf;
    border-radius: 16px;
    width: 24px;
    height: 24px;
    z-index: 9999;

    visibility: hidden;
    opacity: 0;
    transform: translateY(-4px);
    transition: 0.3s;

    .icon {
      font-size: 16px;
    }
  }

  &:hover {
    .iconContainer { 
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: 0.3s;
    }
  }

  .heatMap {
    max-width: 174px;
    height: 160px;
  }

  :global {
    .w-heatmap {
      text:nth-of-type(8) { 
        transform: translateY(48px);
      }

      text:nth-of-type(9) { 
        transform: translateY(48px);
      }
    }
  }
}

.lightContainer {
  .containerHeatMap {
    .calendar {
      tr {
        th {
          color: $davys-grey-5;
        }
      }
  
      .cell {
        .day {
          color: $davys-grey-5;
        }
      }
    
      .cell {
        border-top: 1px solid $chinese-silver-c;
        border-left: 1px solid $chinese-silver-c;
      }
  
      tr:last-child .cell {
        border-bottom: 1px solid $chinese-silver-c;
      }
    
      td:last-child .cell  {
        border-right: 1px solid $chinese-silver-c;
      }
    }

    .iconContainer {
      background-color: $white-bf;
    }
  }

  :global {
    .w-heatmap {
      text {
        fill: $davys-grey-5 !important;
      }
    }
  }
}

.darkContainer {
  .containerHeatMap {
    .calendar {
      tr {
        th {
          color: $philippine-gray;
        }
      }
  
      .cell {
        .day {
          color: $philippine-gray;
        }
      }
    
      .cell {
        border-top: 1px solid $philippine-gray;
        border-left: 1px solid $philippine-gray;
      }
  
      tr:last-child .cell {
        border-bottom: 1px solid $philippine-gray;
      }
    
      td:last-child .cell  {
        border-right: 1px solid $philippine-gray;
      }
    }

    .iconContainer {
      background-color: $sider-background-dark;
    }
  }

  :global {
    .w-heatmap {
      text {
        fill: $philippine-gray !important;
      }
    }
  }
}
