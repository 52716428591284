@use '../../../../common/styleConstants.scss' as *;

.commercialSizeModal { 
  top: 20px;
  
  .switch {
    display: flex;
    justify-content: flex-end;
  
    .status {
      margin-right: 8px;
    }
  }

  .commercialSizesByAnimal {
    user-select: none;
    margin-bottom: 6px;

    .title {
      font-size: 14px;
    }
  
    .description {
      font-size: 13px;
    }
  }
}
