@use '../../../common/styleConstants.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  border-radius: 6px;
  cursor: text;
  
  :global {
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number,
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number:hover {
      background-color: transparent;
    }

    .ant-input-number:hover {
      border-color: transparent;
    }

    .ant-input-number-focused {
      box-shadow: 0 0 0 1px transparent;
      border-color: transparent;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  &:hover {
    box-shadow: 0 0 0 1px $black;
    border: 1px solid $black;
  }

  .titleUnfocused {
    transform: translateY(60%);
  }
}

.darkContainer {
  background-color: $sider-background-dark;
  border: 1px solid $sider-background-dark;

  .titleDark {
    color: $dark-gray-x11;
    font-size: 11px;
    padding-left: 10px;
    letter-spacing: 0.1px;
    transition: all 0.3s ease;
  }

  .darkInputNumber {
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;

    :global {
      .ant-input-number-handler {
        border-color: $dropdown-border-color;
      }

      .ant-input-number-group-addon {
        color: $dark-gray-x11;
        background-color: transparent;
        font-size: 12px;
      }
    }
  }
}

.lightContainer {
  background-color: $lotion;
  border: 1px solid $lotion;

  .titleLight {
    color: $davys-grey-5;
    font-size: 11px;
    padding-left: 10px;
    letter-spacing: 0.1px;
    transition: all 0.3s ease;
  }

  .lightInputNumber {
    background-color: transparent; 
    border: 1px solid transparent;
    width: 100%;

    :global {
      .ant-input-number-input {
        color: $dark-charcoal-3;
      }
      
      .ant-input-number-disabled {
        border: 1px solid $disabled-color;
      }

      .ant-input-number-group-addon {
        background-color: transparent;
        color: $spanish-gray;
        font-size: 12px;
      }
    }
  }
}

.focus {
  box-shadow: 0 0 0 1px $black;
  border: 1px solid $black;
}
