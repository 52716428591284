@use '../../../common/styleConstants.scss' as *;

.container {
  border-radius: 8px;
  padding: 10px 15px 10px 15px;
}

.containerActions {
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    :first-child.action {
      border-radius: 6px 0px 0px 6px;
    }

    :last-child.action {
      border-radius: 0px 6px 6px 0px;
    }

    .action {
      min-width: 65px;
      padding: 3px 8px;
      cursor: pointer;
      transition: 0.3s;
      text-align: center;
    }
  }

  .actions.lightActions {
    .action {
      color: $dark-charcoal-3;
      border: 1px solid $primary-color-light;

      &:not(.selectedAction):hover,
      &:not(.selectedAction):hover {
        background-color: $btn-bg-hover-light;
      }

      &.selectedAction {
        transition: 0.3s;
        background-color: $primary-color-light;
        color: $bright-gray;
      }
    }
  }

  .actions.darkActions {
    .action {
      color: $bright-gray;
      border: 1px solid $primary-color-dark;

      &:not(.selectedAction):hover,
      &:not(.selectedAction):hover {
        background-color: $btn-bg-hover;
      }

      &.selectedAction {
        transition: 0.3s;
        background-color: $primary-color-dark;
        color: $bright-gray;
      }
    }
  }
}
