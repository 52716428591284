@use '../../../common/styleConstants.scss' as *;

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 4;
    stroke-width: 0.5;
  }

  text {
    font-size: 0.9em;
  }
}

.axisY {
  line,
  path {
    stroke: transparent;
  }

  text {
    font-size: 0.9em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.circle {
  stroke-width: 2;
  cursor: pointer;
  stroke: $han-blue;
  fill: $light-silver;
}

.lines {
  stroke: #959595;
  fill: transparent;
  stroke-width: 1;
}

.transferLines {
  stroke: $han-blue;
  fill: transparent;
  stroke-width: 3;
}