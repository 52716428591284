@use '../../../styleConstants.scss' as *;

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 2.5;
    stroke-width: 0.5;
  }

  text {
    font-size: 0.75em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.line {
  stroke: $davys-grey-5;
  fill: transparent;
  stroke-width: 3;
  stroke-linecap: round;
}

.shadowLine {
  filter: blur(2px);
  fill: transparent;
  stroke-width: 4;
  stroke: rgba(0, 0, 0, 0.3);
  stroke-linecap: round;
  transform: translate(0px, 3px);
}

.costPerDayLine {
  stroke: #A6A6A6;
  fill: transparent;
  stroke-width: 3;
  stroke-linecap: round;
}