@use '../../../styleConstants.scss' as *;

.axisBottom {
  line,
  path {
    stroke: $davys-grey-5;
    stroke-width: 0.5;
  }

  text {
    fill: $davys-grey-5;
    font-size: 12px;
  }
}

.axisPrice {
  fill: $davys-grey-5;
  font-size: 12px; 
}

.frequencies {
  transition: 0s;
  fill: $davys-grey-5;
  font-size: 12px;
}

.tooltip {
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  .content {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    min-width: 130px;
    max-width: 160px;
    background-color: white;

    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    padding: 5px 10px 10px;
    color: #000;

    .stat {
      font-size: 10px;
      margin: 0;

      strong {
        font-size: 10px;
      }
    }
  }

  &.leftAlignedTooltip:before,
  &.leftAlignedTooltip:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    left: -16px;
    top: calc(50% - 6px);
  }

  &.leftAlignedTooltip {
    &::before {
      -webkit-transform: rotate(45deg) skew(25deg, 25deg);
      transform: rotate(135deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;

      top: calc(50% - 8px);
      left: -8px;
      right: unset;
    }
  }

  &.bottomAlignedTooltip:before,
  &.bottomAlignedTooltip:after {
    content: "";
    position: absolute;
    border-style: solid;

    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    top: calc(100% + 0.5px);
    left: calc(50% - 8px);

    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  &.bottomAlignedTooltip {
    &::before {
      -webkit-transform: rotate(45deg) skew(25deg, 25deg);
      transform: rotate(45deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 0px 16px 16px 0px;
      z-index: -1;

      top: calc(100% - 8px);
      left: calc(50% - 8px);
      right: unset;
    }
  }
}