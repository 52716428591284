@use '../../../common/styleConstants.scss' as *;

.container {
  position: relative;
  cursor: pointer;
  
  .header {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 3px 8px 0 8px;

    .title {
      font-size: 13px;

      @media (max-width: 1280px) {
        font-size: 11px;
      }

      @media (max-width: 1160px) {
        font-size: 10px;
      }
    }
  
    .iconContainer {
      display: flex;
      justify-content: center;
      
      background-color: $white-bf;
      border-radius: 16px;
      width: 26px;
      height: 26px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(-4px);
      transition: 0.3s;

      @media (max-width: 1280px) {
        width: 24px;
        height: 24px;
      }

      @media (max-width: 1160px) {
        width: 20px;
        height: 20px;
      }

      .icon {
        font-size: 18px;

        @media (max-width: 1280px) {
          font-size: 16px;
        }
  
        @media (max-width: 1160px) {
          font-size: 14px;
        }
      }
    }
  }

  .chart {
    background-color: $lotion;
    border-radius: 6px;
  }

  &:hover {
    .header {
      .iconContainer { 
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: 0.3s;
      }
    }
  }
}

.lightContainer {
  .header {  
    .iconContainer {      
      background-color: $white-bf;
    }
  }

  .chart {
    background-color: $lotion;
  }
}

.darkContainer {
  .header {  
    .iconContainer {      
      background-color: $sider-background-dark;
    }
  }

  .chart {
    background-color: transparent;
  }
}
