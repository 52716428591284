@use '../../../common/styleConstants.scss' as *;

.axisY {
  line,
  path {
    stroke: transparent;
  }
  text {
    font-size: 0.6em;
  }
}

.axisLight {
  text {
    fill: $black;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}
