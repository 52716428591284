
#svgPOC {
  position: relative;
}

.pathLine {
  fill: none;
}

#svgPOC .domain {
  stroke: transparent;
}