@use '../../../common/styleConstants.scss' as *;

.container {
  margin: 6px 24px 18px 24px;
  display: flex;
  justify-content: space-between;

  .options {
    display: flex;
    align-items: center;

    .detailHarvest {
      border-radius: 6px;
      padding: 0 10px;
      border: 1px solid $chinese-silver;
      cursor: pointer;
      
      .icon {
        font-size: 20px;

        @media (max-width: 1280px) {
          font-size: 18px;
        }
      }
    }

    .addPlan {
      span {
        font-size: 14px;
      }
    }

    .lightDetailHarvest {
      &:hover {
        background-color: $lotion;
      }
    }

    .darkDetailHarvest {
      &:hover {
        background-color: $sider-background-dark;
      }
    }
  }
}