.foodChartModal {
  top: 20px;

  .container {
    position: relative;

    .chart {
      position: relative;
      user-select: none;
    }

    .row {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 8px;

      .description {
        display: flex;
        flex-direction: column;
      }

      .select {
        min-width: 170px;
      }
    }

    .labelAxisY {
      font-size: 0.85em;
    }

    .labelAxisX {
      text-align: center;
      font-size: 0.85em;
    }
  }
}